import { API } from "../../../constans/Url"
import { apiClient } from "../../../helper"

const get_KategoriBarangList = (params, callbackSuccess, callbackError) => {
  apiClient(true).get(API.BARANG.KATEGORI.LIST, params).then(res => {
    if (res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

const post_KategoriBarangSave = (params, callbackSuccess, callbackError) => {
  apiClient(true).post(API.BARANG.KATEGORI.SAVE, params).then(res => {
    if (res.status === 201 || res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

const get_KategoriBarangCheckCode = (params, callbackSuccess, callbackError) => {
  apiClient(true).get(API.BARANG.KATEGORI.CHECK, params).then(res => {
    if (res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}


export {
  get_KategoriBarangList,
  post_KategoriBarangSave,
  get_KategoriBarangCheckCode
}